import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { Header, Footer, Loader, Intro, Steps } from "../../components"
import { checkoutFinalImage } from "../../assets"
import { getQueryParams, parseNumber } from "../../utils"
import { getLeadByUUID } from "../../api"

export default function CheckoutThanks() {
  const [lead, setLead] = useState(null)
  useEffect(() => {
    const fetchLead = async () => {
      const po = getQueryParams()?.po
      const lead = await getLeadByUUID(po)
      // Save lead
      setLead(lead)
      if (!lead || !lead.paymentComplete) {
        return navigate("/home")
      }
    }
    fetchLead()
  }, [setLead])
  return (
    <>
      <Header />
      {!lead && <Loader />}
      <div className="checkout-thanks standard-page">
        <div className="content">
          <div className="page-title">Próximos pasos</div>
          <Intro
            image={checkoutFinalImage}
            title="¡Muchas gracias!"
            subtitle={
              lead
                ? `Hemos recibido el pago de $${parseNumber(
                    lead.bookingAmount
                  )} para la reserva de un ${lead.modelName} ${
                    lead.versionName
                  }, con Orden de Compra N° ${
                    lead.id
                  }. Te enviaremos un email con más detalles.`
                : "Hemos recibido el pago para la reserva de un auto nuevo. Te enviaremos un email con más detalles."
            }
          />
          <Steps completed={[1, 2, 3]} />
        </div>
      </div>
      <Footer />
    </>
  )
}
